import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/layout/layout"
import { getImage } from "gatsby-plugin-image"
import Seo from "../../components/layout/seo"
import Hero from "../../components/Global/secondLevelHero/secondLevelHero"
import Graphs from "../../components/Global/graphAndText/graphAndText"
import FullWidthText from "../../components/Global/fullWidthText/fullWidthText"
import FullWidthImage from "../../components/Global/fullWidthImage/fullWidthImage"
import FullWidthCTA from "../../components/Global/fullWidthCta/fullWidthCta"
import Footnotes from "../../components/Global/footnotes/footnotes"
import Footer from "../../components/layout/footer"

const EfficacyPage = ({ data }) => {

  const content = data.allContentfulEfficacyData.nodes[0];

  let heroData = {
    breadcrumbs: content.hero.breadcrumb,
    title: content.hero.title,
    content: content.hero.content,
    backgroundImage: content.hero.backgroundImage,
    backPos: "top right"
  }

  let graphsData = content.graphs
  let graphsOutro = content.graphsOutro.text

  let fullWidthBanner1Data = {
    imageTitle: content.fullWidthBanner1.image.title,
    image: getImage(content.fullWidthBanner1.image),
  }

  let graphsBottomData = content.graphsBottom

  let fullWidthBanner2Data = {
    imageTitle: content.fullWidthBanner2.image.title,
    image: getImage(content.fullWidthBanner2.image),
  }

  let fullWidthCtaData = {
    title: content.relatedPages.title,
    buttons: content.relatedPages.buttons
  }

  let footnotesData = {
    pageJobCode: content.jobCodeDate,
    footnotesData: content.footerData,
  }

  return (
    <Layout>
      <Seo title="Efficacy data" />
      <Hero data={heroData}/>
      <Graphs data={graphsData} />
      <FullWidthText data={graphsOutro} />
      <FullWidthImage data={fullWidthBanner1Data} />
      <Graphs data={graphsBottomData} />
      <FullWidthCTA data={fullWidthCtaData} />
      <FullWidthImage data={fullWidthBanner2Data} />
      <Footnotes data={footnotesData} />
      <Footer/>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulEfficacyData {
      nodes {
        hero {
          breadcrumb {
            title
            url
          }
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          backgroundImage {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
        }
        graphs {
          title {
            childMarkdownRemark {
              html
            }
          }
          graph {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
          textTop {
            childMarkdownRemark {
              html
            }
          }
          textMiddle {
            childMarkdownRemark {
              html
            }
          }
          textBottom {
            childMarkdownRemark {
              html
            }
          }
          footnote {
            childMarkdownRemark {
              html
            }
          }
        }
        graphsOutro {
          text {
            childMarkdownRemark {
              html
            }
          }
        }
        fullWidthBanner1 {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        graphsBottom {
          title {
            childMarkdownRemark {
              html
            }
          }
          graph {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }
          textTop {
            childMarkdownRemark {
              html
            }
          }
          textMiddle {
            childMarkdownRemark {
              html
            }
          }
          textBottom {
            childMarkdownRemark {
              html
            }
          }
          footnote {
            childMarkdownRemark {
              html
            }
          }
        }
        relatedPages {
          title {
            childMarkdownRemark {
              html
            }
          }
          buttons {
            buttonText {
              childMarkdownRemark {
                html
              }
            }
            buttonUrl
          }
        }
        fullWidthBanner2 {
          image {
            gatsbyImageData(
              layout: FULL_WIDTH
            )
            title
          }         
        }
        footerData {
          title
          content {
            childMarkdownRemark {
              html
            }
          }
          id
        }
        jobCodeDate
      }
    }
  }
`

export default EfficacyPage