// extracted by mini-css-extract-plugin
export var active = "graphAndText-module--active--e4693";
export var bigWhite = "graphAndText-module--big-white--65bb5";
export var blue = "graphAndText-module--blue--d3bf0";
export var blueBg = "graphAndText-module--blue-bg--5807d";
export var center = "graphAndText-module--center--d3c40";
export var cookie__customise = "graphAndText-module--cookie__customise--f31ef";
export var cookie__customise__buttons = "graphAndText-module--cookie__customise__buttons--ee0ae";
export var cookie__customise__buttons__allow = "graphAndText-module--cookie__customise__buttons__allow--cffde";
export var cookie__customise__buttons__disable = "graphAndText-module--cookie__customise__buttons__disable--f7225";
export var cookie__customise__buttons__save = "graphAndText-module--cookie__customise__buttons__save--9b569";
export var cookie__customise__close = "graphAndText-module--cookie__customise__close--49d44";
export var cookie__customise__content = "graphAndText-module--cookie__customise__content--0ac42";
export var cookie__customise__content__title = "graphAndText-module--cookie__customise__content__title--fa282";
export var cookie__customise__overlay = "graphAndText-module--cookie__customise__overlay--67126";
export var cookie__popup = "graphAndText-module--cookie__popup--bfab1";
export var cookie__popup__button = "graphAndText-module--cookie__popup__button--ceb88";
export var cookie__popup__close = "graphAndText-module--cookie__popup__close--b3c43";
export var cookie__popup__content = "graphAndText-module--cookie__popup__content--ae9a2";
export var cookie__popup__text = "graphAndText-module--cookie__popup__text--cb23a";
export var decreaseHeight = "graphAndText-module--decrease-height--fbcae";
export var embed_responsive = "graphAndText-module--embed_responsive--cb60f";
export var embed_responsive_16by9 = "graphAndText-module--embed_responsive_16by9--f36ac";
export var embed_responsive_item = "graphAndText-module--embed_responsive_item--6d3b9";
export var flexUl = "graphAndText-module--flex-ul--0e669";
export var fullWidthCtaModuleFullWidthCtaFdc73 = "graphAndText-module--fullWidthCta-module--fullWidthCta--fdc73--cef69";
export var fullWidthTextModuleIntroText2b0e4 = "graphAndText-module--fullWidthText-module--introText--2b0e4--5624a";
export var graphText = "graphAndText-module--graphText--1feb6";
export var graphText__item = "graphAndText-module--graphText__item--0cb66";
export var graphText__item__imageArea = "graphAndText-module--graphText__item__imageArea--2e898";
export var graphText__item__imageArea__footnote = "graphAndText-module--graphText__item__imageArea__footnote--d7b07";
export var graphText__item__imageArea__graph = "graphAndText-module--graphText__item__imageArea__graph--397cb";
export var graphText__item__info = "graphAndText-module--graphText__item__info--da74c";
export var graphText__item__info__bottom = "graphAndText-module--graphText__item__info__bottom--cf045";
export var graphText__item__info__middle = "graphAndText-module--graphText__item__info__middle--cb60d";
export var graphText__item__info__top = "graphAndText-module--graphText__item__info__top--1c0ec";
export var header = "graphAndText-module--header--cf2dc";
export var iframeContainer = "graphAndText-module--iframe-container--a0117";
export var indicationSection = "graphAndText-module--indication-section--e27cc";
export var left = "graphAndText-module--left--ddab9";
export var overlay = "graphAndText-module--overlay--6f9e6";
export var removeMobile = "graphAndText-module--remove-mobile--14e55";
export var responsiveIframe = "graphAndText-module--responsive-iframe--364fc";
export var secondLevelHeroModuleBanner__subTitle8b2b5 = "graphAndText-module--secondLevelHero-module--banner__subTitle--8b2b5--2605d";
export var singleTimestamp = "graphAndText-module--single-timestamp--f5081";
export var slider = "graphAndText-module--slider--dc48e";
export var small = "graphAndText-module--small--8f7f6";
export var standard = "graphAndText-module--standard--92f13";
export var switcher = "graphAndText-module--switcher--788d0";
export var timestamps = "graphAndText-module--timestamps--f33f3";
export var videoArea = "graphAndText-module--videoArea--14c40";
export var videoResource = "graphAndText-module--video-resource--19952";
export var videoTime = "graphAndText-module--videoTime--736b0";
export var video__iframe = "graphAndText-module--video__iframe--59104";
export var yell = "graphAndText-module--yell--729d1";