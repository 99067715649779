import React from "react"
import * as styles from "./graphAndText.module.scss"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

function graphAndText({ data }) {
  const graphs = data.map(graph => {

    const graphImage = getImage(graph.graph)

    return (
      <div className={styles.graphText__item}>

        <div className={styles.graphText__item__imageArea}>
          <div className={styles.graphText__item__imageArea__title} dangerouslySetInnerHTML={{ __html: graph.title.childMarkdownRemark.html }}/>

          <div className={styles.graphText__item__imageArea__graph}>
            <GatsbyImage image={graphImage} />
          </div>

          <div className={styles.graphText__item__imageArea__footnote} dangerouslySetInnerHTML={{ __html: graph.footnote.childMarkdownRemark.html }}/>
        </div>

        <div className={styles.graphText__item__info}>
          <div className={styles.graphText__item__info__top} dangerouslySetInnerHTML={{ __html: graph.textTop.childMarkdownRemark.html }}/>
          <div className={styles.graphText__item__info__middle} dangerouslySetInnerHTML={{ __html: graph.textMiddle.childMarkdownRemark.html }}/>
          <div className={styles.graphText__item__info__bottom} dangerouslySetInnerHTML={{ __html: graph.textBottom.childMarkdownRemark.html }}/>
        </div>
        
      </div>
    )

  });

  return (
    <div className={styles.graphText}>
      {graphs}
    </div>
  )
}

export default graphAndText